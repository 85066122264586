@media print {
  bng-app-root,
  lay-layout,
  #boss-body .boss-content {
    height: unset !important;
    overflow: unset !important;
    overflow-y: unset !important;
    background-color: #fff !important;
    margin-left: unset !important;
  }

  bz-business-header > .container {
    background-color: transparent !important;
    padding-left: 0 !important;
    h1 {
      color: black !important;
    }
  }

  #business-header-misc,
  #business-header-icons,
  #business-header-end,
  .title,
  .menu,
  .mat-mdc-tab-nav-bar {
    display: none !important;
  }
}
