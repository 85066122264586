@import 'mixins';

@mixin mat-icon-button-size($name, $buttonSize, $iconSize) {
  .mat-#{$name}-icon-button {
    width: $buttonSize !important;
    height: $buttonSize !important;
    padding: 0px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role='img'],
    fa-icon {
      @include mat-icon-size($iconSize);

      svg {
        width: $iconSize;
        height: $iconSize;
      }
    }

    .mat-mdc-button-touch-target {
      width: $buttonSize !important;
      height: $buttonSize !important;
    }
  }
}

@include mat-icon-button-size('xl', 47px, 28px);
@include mat-icon-button-size('large', 40px, 24px);
@include mat-icon-button-size('medium', 32px, 19px);
@include mat-icon-button-size('small', 26px, 16px);
@include mat-icon-button-size('tiny', 20px, 12px);
