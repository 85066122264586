//
// Variables
// --------------------------------------------------
$header-height: 64px;
$header-height-phone: 56px;
$page-header-height: 64px;
$page-header-height-lg: 72px;
$page-header-height-phone: 56px;
$well-header-height: 36px;
$business-header-height: 60px;

$mat-progress-bar-height: 4px;

$tight-sidebar-width: 60px;

$mat-card-title-height: 48px;

// Modal-related variables
$modal-padding-x: 20px;
$modal-padding-y: 55px;
$modal-header-height: 50px;
$modal-footer-height: 76px;

$modal-height-desktop: 80vh;
$modal-height-mobile: 100vh;

//== Colors
//
//## Gray and brand colors.
$becks-green: #00693f;
$white: #fff;
$black: #000;
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$disabled-color: rgba(0, 0, 0, 0.26);
$disabled-background: rgba(0, 0, 0, 0.12);

//** CUSTOM COLORS **
$secondary: #7dae42;
$secondary-dark: #709b3b;
$secondary-bolder: #5caf01;
$secondary-opacity: #84bc85;
$secondary-bold: #5eaf00;
$black-87-opacity: rgba($black, 0.87);
$white-87-opacity: rgba($white, 0.87);
$black-12-opacity: rgba($black, 0.12);
$white-12-opacity: rgba($white, 0.12);
$black-6-opacity: rgba($black, 0.06);
$white-6-opacity: rgba($white, 0.06);

$gray-0-25-opacity: #f3f3f3;
$gray-1-opacity: #fafafa;
$gray-2-opacity: #efefef;
$gray-3-opacity: #e7e7e7;
$gray-4-opacity: #e0e0e0;
$gray-5-opacity: #bdbdbd;
$gray-6-opacity: #9e9e9e;
$gray-7-opacity: #757575;
$gray-8-opacity: #616161;
$gray-9-opacity: #424242;
$gray-10-opacity: #212121;

$brand-warning: #f00;
$past-due: #b80c0c;
$inverse: #3f51b5;
$holds: #bd8503;
$needs-review: #f6cc00;
$status-red: #c92121;
$status-red-light: #ffe4e4;
$status-green: #55871c;
$status-green-light: #e9ffd0;

$primary: #6098f0;
$primary-dark: #406aac;
$primary-dark-600: #385d99;
$primary-dark-700: #325286;
$primary-dark-800: #2b4773;
$primary-dark-900: #243b61;
$primary-light-1: #ebf3ff;
$primary-light-2: #bfd5f8;
$primaryhvr: #4b79c4;
$action-button: $primary-dark;
$delete-button: #b80c0c;
$accent: #003f91;
$accent2: #00428d;

$cb-blue: #4b79c4;
$cb-orange: #ffb200;

$advise-against-red: #e5505a;

$corn-orange: #fcab37;
$corn-orange-dark: darken($corn-orange, 20%);
$soybean-green: #7dae42;
$soybean-green-dark: darken($soybean-green, 10%);
$wheat-blue: #2c80aa;
$wheat-blue-dark: darken($wheat-blue, 20%);
$wheat-yellow: #f3c402;
$alfalfa-purple: #9737ac;
$alfalfa-purple-dark: darken($alfalfa-purple, 20%);
$other-gray: #4c4c4c;
$promo: #1fab89;

$neutral-300: #d4d4d4;
$neutral-400: #a3a3a3;
$neutral-500: #737373;
$neutral-600: #525252;

$red-500: #ef4444;

$order-green: #6cad23;
$order-green-dark: darken($order-green, 5%);

$label-text-color: #909090;

$link-hover-decoration: underline !default;

// use these colors for status indicator colors
$active-indicator: #42c72b;
$inactive-indicator: #c6c6c6;

$gold: #b59600;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Roboto', Arial, sans-serif !default;

//== Media Queries
// When managing these breakpoints, the same changes need to be made to the layout.component.ts file, in the _getBreakpointEntities function and the breakpoints.interface.ts file should be updated as well based on the changes being made here.

//== Sidebar
//
//##

// Basics of Sidebar
$sidebar-width: 224px !default;
$sidebar-background: #212121;
$sidebar-active: #424242;
$sidebar-active-text: #fafafa;
$sidebar-icon: #aeaeae;

//** MATERIAL DESIGN CUSTOMIZATION **
$left-nav-width: 60px;

// MODAL Building Blocks
$modal-block-margin: 20px;
$modal-block-margin-mobile: 5px;

// Atoms (inspired by tailwindcss)
// :root {
//   --ring-inset: ;
//   --ring-offset-width: 0px;
//   --ring-color: currentColor;
//   --ring-opacity: 1;
//   --ring-offset-color: white;
//   --space-y-reverse: 0;
//   --space-x-reverse: 0;
//   --shadow: 0 0 #0000;
//   --border-style: solid;
// }

$basic-shadow:
  0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14),
  0px 1px 3px 0px rgba(0, 0, 0, 0.12);
