@import 'variables';

// 0.02px is the granular amount between full pixels when resizing
$XXL: 1920px;
$XLMax: $XXL - 0.02px;
$XL: 1400px;
$LGMax: $XL - 0.02px;
$LG: 1280px;
$MDMax: $LG - 0.02px;
$MD: 960px;
$SMMax: $MD - 0.02px;
$SM: 600px;
$XSMax: $SM - 0.02px;
$XS: 0;

@mixin breakpoints($points...) {
  @each $point in $points {
    @if $point == xxl or $point == xxlUp {
      @media screen and (min-width: $XXL) {
        @content;
      }
    } @else if $point == xxlLandscape {
      @media screen and (min-width: $XXL) and (orientation: landscape) {
        @content;
      }
    } @else if $point == xl {
      @media screen and (min-width: $XL) and (max-width: $XLMax) {
        @content;
      }
    } @else if $point == xlLandscape {
      @media screen and (min-width: $XL) and (max-width: $XLMax) and (orientation: landscape) {
        @content;
      }
    } @else if $point == xlUp {
      @media screen and (min-width: $XL) {
        @content;
      }
    } @else if $point == xlDown {
      @media screen and (max-width: $XLMax) {
        @content;
      }
    } @else if $point == lg {
      @media screen and (min-width: $LG) and (max-width: $LGMax) {
        @content;
      }
    } @else if $point == lgLandscape {
      @media screen and (min-width: $LG) and (max-width: $LGMax) and (orientation: landscape) {
        @content;
      }
    } @else if $point == lgUp {
      @media screen and (min-width: $LG) {
        @content;
      }
    } @else if $point == lgDown {
      @media screen and (max-width: $LGMax) {
        @content;
      }
    } @else if $point == md {
      @media screen and (min-width: $MD) and (max-width: $MDMax) {
        @content;
      }
    } @else if $point == mdLandscape {
      @media screen and (min-width: $MD) and (max-width: $MDMax) and (orientation: landscape) {
        @content;
      }
    } @else if $point == mdUp {
      @media screen and (min-width: $MD) {
        @content;
      }
    } @else if $point == mdDown {
      @media screen and (max-width: $MDMax) {
        @content;
      }
    } @else if $point == sm {
      @media screen and (min-width: $SM) and (max-width: $SMMax) {
        @content;
      }
    } @else if $point == smLandscape {
      @media screen and (min-width: $SM) and (max-width: $SMMax) and (orientation: landscape) {
        @content;
      }
    } @else if $point == smUp {
      @media screen and (min-width: $SM) {
        @content;
      }
    } @else if $point == smDown {
      @media screen and (max-width: $SMMax) {
        @content;
      }
    } @else if $point == xs or $point == xsDown {
      @media screen and (min-width: 0px) and (max-width: $XSMax) {
        @content;
      }
    } @else if $point == xsLandscape {
      @media screen and (min-width: 0px) and (max-width: $XSMax) and (orientation: landscape) {
        @content;
      }
    } @else if $point == xsUp or $point == xxlDown {
      @media screen and (min-width: 0px) {
        @content;
      }
    }
  }
}

@mixin mat-icon-size($size) {
  font-size: $size;
  height: $size;
  width: $size;
}

@mixin bng-progress-spinner-size($size: 30) {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .mat-progress-spinner {
    height: $size !important; // important here is needed to override the material element level styles
    width: $size !important; // important here is needed to override the material element level styles

    svg {
      height: $size !important; // important here is needed to override the material element level styles
      width: $size !important; // important here is needed to override the material element level styles
    }
  }
}

@mixin family-colors {
  background-color: $other-gray;

  &.CORN {
    background-color: $corn-orange;
  }

  &.SOYBEANS {
    background-color: $soybean-green;
  }

  &.WHEAT {
    background-color: $wheat-blue;
  }
  &.PROMO {
    background-color: $promo;
  }
  &.EL.ALF,
  &.OATS.SG,
  &.OTHERCROP {
    background-color: $alfalfa-purple;
  }
}

@mixin family-fill-colors {
  fill: $other-gray;

  &.CORN {
    fill: $corn-orange;
  }

  &.SOYBEANS {
    fill: $soybean-green;
  }

  &.WHEAT {
    fill: $wheat-blue;
  }

  &.EL.ALF,
  &.OATS.SG,
  &.OTHERCROP {
    fill: $alfalfa-purple;
  }
}

@mixin family-text-colors {
  color: $other-gray;

  &.CORN {
    color: $corn-orange;
  }

  &.SOYBEANS {
    color: $soybean-green;
  }

  &.WHEAT {
    color: $wheat-blue;
  }

  &.EL.ALF,
  &.OATS.SG,
  &.OTHERCROP {
    color: $alfalfa-purple;
  }
}

@mixin family-text-colors-dark {
  color: $other-gray;

  &.CORN {
    color: $corn-orange-dark;
  }

  &.SOYBEANS {
    color: $soybean-green-dark;
  }

  &.WHEAT {
    color: $wheat-blue-dark;
  }

  &.EL.ALF,
  &.OATS.SG,
  &.OTHERCROP {
    color: $alfalfa-purple-dark;
  }
}

@mixin family-border-colors {
  border-left-color: $other-gray !important;

  &.CORN {
    border-left-color: $corn-orange !important;
  }

  &.SOYBEANS {
    border-left-color: $soybean-green !important;
  }

  &.WHEAT {
    border-left-color: $wheat-blue !important;
  }

  &.EL.ALF,
  &.OATS.SG,
  &.OTHERCROP {
    border-left-color: $alfalfa-purple !important;
  }
}

@mixin empty {
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    padding: 64px 0;
    margin-top: 8px;
    color: white;
    font-size: 26px;
    text-shadow: 1px 1px #c7c7c7;
  }
}

@function tw-number($number) {
  @if ($number == '0') {
    @return 0px;
  } @else if ($number == '0.5') {
    @return 0.125rem;
  } @else if ($number == '1') {
    @return 0.25rem;
  } @else if ($number == '1.5') {
    @return 0.375rem;
  } @else if ($number == '2') {
    @return 0.5rem;
  } @else if ($number == '4') {
    @return 1rem;
  } @else if ($number == '5') {
    @return 1.25rem;
  } @else if ($number == '6') {
    @return 1.5rem;
  } @else if ($number == '7') {
    @return 1.75rem;
  } @else if ($number == '8') {
    @return 2rem;
  } @else if ($number == '10') {
    @return 2.5rem;
  } @else if ($number == '14') {
    @return 3.5rem;
  } @else if ($number == '16') {
    @return 4rem;
  } @else if ($number == '18') {
    @return 4.5rem;
  } @else if ($number == '20') {
    @return 5rem;
  } @else if ($number == '24') {
    @return 6rem;
  } @else if ($number == '28') {
    @return 7rem;
  } @else if ($number == '40') {
    @return 10rem;
  } @else if ($number == '44') {
    @return 11rem;
  } @else if ($number == '48') {
    @return 12rem;
  } @else if ($number == '56') {
    @return 14rem;
  } @else if ($number == '80') {
    @return 20rem;
  } @else if ($number == '96') {
    @return 24rem;
  } @else if ($number == '1/3') {
    @return 33.333333%;
  } @else if ($number == '1/2') {
    @return 50%;
  } @else if ($number == '2/3') {
    @return 66.666667%;
  } @else if ($number == '1/4') {
    @return 25%;
  } @else if ($number == '3/4') {
    @return 75%;
  } @else if ($number == '3/12') {
    @return 25%;
  } @else if ($number == '4/12') {
    @return 33.333333%;
  } @else if ($number == '5/12') {
    @return 41.666667%;
  } @else if ($number == '7/12') {
    @return 58.333333%;
  } @else if ($number == '8/12') {
    @return 66.666667%;
  } @else if ($number == '9/12') {
    @return 75%;
  } @else if ($number == '10/12') {
    @return 83.333333%;
  } @else if ($number == '11/12') {
    @return 91.666667%;
  }
}

@mixin p-($padding) {
  padding: tw-number($padding);
}
@mixin px-($padding) {
  padding-left: tw-number($padding);
  padding-right: tw-number($padding);
}
@mixin py-($padding) {
  padding-top: tw-number($padding);
  padding-bottom: tw-number($padding);
}
@mixin pt-($padding) {
  padding-top: tw-number($padding);
}
@mixin pb-($padding) {
  padding-bottom: tw-number($padding);
}
@mixin pr-($padding) {
  padding-right: tw-number($padding);
}
@mixin pl-($padding) {
  padding-left: tw-number($padding);
}

@mixin w-($width) {
  width: tw-number($width);
}
@mixin min-w-($width) {
  min-width: tw-number($width);
}

@mixin h-($width) {
  height: tw-number($width);
}

@mixin mb-($size) {
  margin-bottom: tw-number($size);
}
@mixin mt-($size) {
  margin-top: tw-number($size);
}
@mixin mx-($size) {
  margin-right: tw-number($size);
  margin-left: tw-number($size);
}

@mixin text-sm() {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
@mixin text-base() {
  font-size: 1rem;
  line-height: 1.5rem;
}
@mixin text-lg() {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
@mixin text-xl() {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin rounded {
  border-radius: 0.25rem;
}
@mixin rounded-md {
  border-radius: 0.375rem;
}
@mixin rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
@mixin rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
@mixin rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
@mixin rounded-lg {
  border-radius: 0.5rem;
}
@mixin rounded-full {
  border-radius: 9999px;
}

@mixin shadow() {
  --shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.1), 0 1px 1px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--ring-offset-shadow, 0 0 #0000), var(--ring-shadow, 0 0 #0000), var(--shadow);
}
@mixin shadow-md() {
  --shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.07), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--ring-offset-shadow, 0 0 #0000), var(--ring-shadow, 0 0 #0000), var(--shadow);
}

@mixin font-semibold() {
  font-weight: 600;
}
@mixin font-bold() {
  font-weight: 700;
}
@mixin space-y-($amount) {
  > :not([hidden]) ~ :not([hidden]) {
    margin-top: calc(#{tw-number($amount)} * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(#{tw-number($amount)} * calc(var(--space-y-reverse)));
  }
}

@mixin space-x-($amount) {
  > :not([hidden]) ~ :not([hidden]) {
    margin-right: calc(#{tw-number($amount)} * calc(var(--space-x-reverse)));
    margin-left: calc(#{tw-number($amount)} * calc(1 - var(--space-x-reverse)));
  }
}

@mixin position-abs-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
