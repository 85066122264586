@use '@angular/material' as mat;
@import '@becksdevteam/font-awesome/scss/fontawesome';
@import 'angular-calendar/scss/angular-calendar.scss';

@import '../node_modules/@becksdevteam/angular-common/datetime-picker/assets/styles/picker.min';
@import '../node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';

// Local imports
@import 'variables';
@import 'custom-palette';
@import 'mixins';
@import 'grid';
@import 'print';
@import 'mat-icon-button-size';
@import 'mat_form_field';

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

$boss-app-primary: mat.m2-define-palette($boss-primary);
$boss-app-accent: mat.m2-define-palette($boss-accent);
$boss-app-warn: mat.m2-define-palette($boss-warn);
$boss-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $boss-app-primary,
      accent: $boss-app-accent,
      warn: $boss-app-warn
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
  )
);

@include mat.core();
@include mat.all-component-themes($boss-app-theme);

// Button Theme - Buttons have a different accent color than the main theme, overriding just accent
$boss-button-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $boss-app-primary,
      accent: mat.m2-define-palette($boss-button-accent),
      warn: $boss-app-warn
    )
  )
);
// Need to set styles with button-color instead of button-theme to prevent style duplication
@include mat.button-color($boss-button-theme);

.green-button {
  $boss-button-green-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: mat.m2-define-palette($boss-button-green),
        accent: mat.m2-define-palette($boss-button-green-accent),
        warn: $boss-app-warn
      )
    )
  );

  @include mat.button-color($boss-button-green-theme);
}

// Fonts
@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto/Roboto-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

// Global layout wrapper
html,
body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto', Arial, sans-serif;
  min-height: calc((var(--vh, 1vh) * 100));
  width: 100%;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
}

bng-app-root,
lay-layout {
  width: 100%;
  overflow: hidden;
  display: block;
}

// Global element overrides
a {
  color: #2e63b7;
  text-decoration: none;
  cursor: pointer;
}

a :visited {
  color: #2e63b7;
}

h1 {
  font-size: 1.5rem;
  font-weight: bolder;
}

h2 {
  font-size: 1.375rem;
  font-weight: bolder;
}

h3 {
  font-size: 1.25rem;
  color: black;
  font-weight: bolder;
}

h4 {
  font-size: 1.125rem;
  color: #777777;
  font-weight: bolder;
}

h5 {
  font-size: 0.875rem;
}

// Global class helpers
@include grid();

.host {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.force-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.force-nowrap {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.force-hidden {
  display: none !important;
}

@include breakpoints(smDown) {
  .force-hidden-on-mobile {
    display: none !important;
  }
}

.force-invisible {
  visibility: hidden !important;
}

.clickable {
  cursor: pointer;
}

.unclickable,
.hoverable {
  cursor: default;
}

.card-lookalike {
  -moz-box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

// Material class overrides
.mat-mdc-card.slide-up,
bng-modal,
.cdk-overlay-container {
  z-index: 5000 !important;
}

.mat-sidenav-content {
  overflow: hidden !important;
}

// Styling for primary themes is broken for raised, flat buttons and checkboxes... who knows what else
// Github Issue: https://github.com/angular/components/issues/26179
.mat-primary {
  &.mat-mdc-raised-button:not(:disabled),
  &.mat-mdc-flat-button:not(:disabled),
  & .mdc-checkbox__checkmark {
    color: $white !important;
  }
}

.mdc-button {
  // If an fa icon is used inside a material button we can just put mat-icon class on it
  // This is here to fix current fa icons
  fa-icon,
  .fa-icon,
  .ng-fa-icon {
    display: inline-block;
    margin-right: 8px;
  }
  &.icon-only .mat-icon {
    margin-right: 0;
  }

  &:not(:disabled).second {
    background-color: $secondary !important;
    color: $white !important;

    .mat-ripple-element {
      background-color: ($secondary, 0.2) !important;
    }
  }

  &.button-loading {
    .mdc-button__label {
      color: rgba(0, 0, 0, 0.1);
    }

    .mat-mdc-progress-spinner {
      @include position-abs-center;
    }
  }
}

.mat-mdc-card {
  padding: 16px;
  color: #000;
  .mat-mdc-card-header,
  .mat-mdc-card-content,
  .mat-mdc-card-actions {
    padding: 0;
  }
}

.mat-mdc-card-title {
  margin-bottom: 8px !important;
}

.mat-mdc-snack-bar-container {
  &.success > .mdc-snackbar__surface {
    background-color: $primary-dark;
  }
  &.error > .mdc-snackbar__surface {
    background-color: $advise-against-red;
  }
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:not([mat-stretch-tabs]),
  &[mat-stretch-tabs='false'] {
    .mdc-tab {
      width: 160px;
    }
  }
}

.mdc-tooltip {
  &__surface {
    white-space: pre-line !important;
    text-align: left !important;
  }
  &.max-width-unset {
    .mdc-tooltip__surface {
      max-width: unset !important;
    }
  }
}

.mat-toolbar-row {
  h2,
  h3 {
    font-weight: normal;
  }
}

// Begin Slideup
// Use this class on material cards that are being treated as slide-ups.  Example: https://projects.invisionapp.com/d/main#/console/13160614/275163056/preview
.mat-mdc-card.slide-up {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  padding: 0;
  right: 0;
  transition: left 400ms ease-in-out;

  @include breakpoints(xs) {
    height: 100vh;
    padding-bottom: 55px;
  }

  @include breakpoints(xsLandscape) {
    top: 62px;
  }
}

.nav-menu-open .mat-mdc-card.slide-up {
  left: 220px;

  @include breakpoints(smDown) {
    left: 0;
  }
}

.nav-menu-closed.mobile .mat-mdc-card.slide-up {
  left: 0;
}
// End Slideup
.mat-mdc-mini-fab,
.mat-mdc-fab {
  position: absolute !important;
  right: 16px;
  bottom: 16px;
  z-index: 100;

  @supports (-webkit-touch-callout: none) {
    bottom: 56px;

    @include breakpoints(xsDown) {
      bottom: 96px;
    }

    @include breakpoints(smLandscape, xsLandscape) {
      bottom: 16px;
    }
  }
}

.mat-icon {
  overflow: visible !important;
}

// The styles here are for the context menu in the skid builder calendar
// Styles are in the global space because the context menu is rendered as an overlay
.ngx-contextmenu {
  /* Styling of the context menu itself */
  --ngx-contextmenu-font-family: Roboto, Arial, sans-serif;
  --ngx-contextmenu-background-color: white;
  --ngx-contextmenu-border-radius: 0;
  --ngx-contextmenu-border: none;
  --ngx-contextmenu-box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  --ngx-contextmenu-font-size: 16px;
  --ngx-contextmenu-margin: 2px 0 0;
  --ngx-contextmenu-min-width: 0;
  --ngx-contextmenu-outline: 1px solid #70757e;
  --ngx-contextmenu-padding: 0;
  --ngx-contextmenu-text-color: black;
  --ngx-contextmenu-text-disabled-color: #8a909a;

  /* Styling of context menu items */
  --ngx-contextmenu-item-background-hover-color: #e5e5e5;
  --ngx-contextmenu-item-separator-color: #8a909a;
  --ngx-contextmenu-item-separator-padding: 10px;
  --ngx-contextmenu-item-separator-width: 96%;
  --ngx-contextmenu-item-padding: 6px 20px;
  --ngx-contextmenu-item-text-hover-color: #5a6473;

  button:hover {
    cursor: pointer;
  }
}

.mat-mdc-form-field {
  display: block !important;

  &.no-subscript {
    > .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.container {
  width: 100% !important;
  padding: 1rem !important;
  max-width: initial !important;
}

.grid {
  gap: 1rem;
}

.position-abs-center {
  @include position-abs-center;
}
