@import 'mixins';

/*!
 * Based on Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

/*!
 * Use Docs at https://getbootstrap.com/docs/5.0/layout/grid/
 * Changes (to conform to our breakpoints, and to avoid CSS class conflicts):
 * - Breakpoints match those in _mixins
 * - .container becomes .grid-container
 * - .row becomes .grid-row
 * - .col becomes .grid-col
 * - .offset becomes .grid-offset
 * - .y- becomes .grid-row-spacing-
 * - .x- becomes .grid-col-spacing-
 */
@mixin grid() {
  .grid-container,
  .grid-container-fluid,
  .grid-container-xl,
  .grid-container-lg,
  .grid-container-md,
  .grid-container-sm,
  .grid-container-xs {
    --bs-gutter-default: 16px;
    width: 100%;
    padding-right: var(--bs-gutter-x, 16px);
    padding-left: var(--bs-gutter-x, 16px);
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: $SM) {
    .grid-container-sm,
    .grid-container {
      max-width: calc($SM - (var(--bs-gutter-default) * 3));
    }
  }
  @media (min-width: $MD) {
    .grid-container-md,
    .grid-container-sm,
    .grid-container {
      max-width: calc($MD - (var(--bs-gutter-default) * 4));
    }
  }
  @media (min-width: $LG) {
    .grid-container-lg,
    .grid-container-md,
    .grid-container-sm,
    .grid-container {
      max-width: calc($LG - (var(--bs-gutter-default) * 4));
    }
  }
  @media (min-width: $XL) {
    .grid-container-xl,
    .grid-container-lg,
    .grid-container-md,
    .grid-container-sm,
    .grid-container {
      max-width: calc($XL - (var(--bs-gutter-default) * 4));
    }
  }
  .grid-container > .grid-row {
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
  }
  .grid-row {
    --bs-gutter-x: 16px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
  }
  .grid-row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  .grid-col {
    flex: 1 0 0%;
  }

  .grid-row-cols-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .grid-row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .grid-row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .grid-row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .grid-row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .grid-row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .grid-row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  @media (min-width: $SM) {
    .grid-col-sm {
      flex: 1 0 0%;
    }

    .grid-row-cols-sm-auto > * {
      flex: 0 0 auto;
      width: auto;
    }

    .grid-row-cols-sm-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }

    .grid-row-cols-sm-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }

    .grid-row-cols-sm-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .grid-row-cols-sm-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }

    .grid-row-cols-sm-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }

    .grid-row-cols-sm-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  @media (min-width: $MD) {
    .grid-col-md {
      flex: 1 0 0%;
    }

    .grid-row-cols-md-auto > * {
      flex: 0 0 auto;
      width: auto;
    }

    .grid-row-cols-md-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }

    .grid-row-cols-md-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }

    .grid-row-cols-md-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .grid-row-cols-md-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }

    .grid-row-cols-md-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }

    .grid-row-cols-md-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  @media (min-width: $LG) {
    .grid-col-lg {
      flex: 1 0 0%;
    }

    .grid-row-cols-lg-auto > * {
      flex: 0 0 auto;
      width: auto;
    }

    .grid-row-cols-lg-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }

    .grid-row-cols-lg-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }

    .grid-row-cols-lg-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .grid-row-cols-lg-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }

    .grid-row-cols-lg-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }

    .grid-row-cols-lg-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  @media (min-width: $XL) {
    .grid-col-xl {
      flex: 1 0 0%;
    }

    .grid-row-cols-xl-auto > * {
      flex: 0 0 auto;
      width: auto;
    }

    .grid-row-cols-xl-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }

    .grid-row-cols-xl-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }

    .grid-row-cols-xl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .grid-row-cols-xl-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }

    .grid-row-cols-xl-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }

    .grid-row-cols-xl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }

  .grid-col-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .grid-col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .grid-col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .grid-col-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .grid-col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .grid-col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .grid-col-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .grid-col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .grid-col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .grid-col-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .grid-col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .grid-col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .grid-col-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .grid-offset-1 {
    margin-left: 8.33333333%;
  }

  .grid-offset-2 {
    margin-left: 16.66666667%;
  }

  .grid-offset-3 {
    margin-left: 25%;
  }

  .grid-offset-4 {
    margin-left: 33.33333333%;
  }

  .grid-offset-5 {
    margin-left: 41.66666667%;
  }

  .grid-offset-6 {
    margin-left: 50%;
  }

  .grid-offset-7 {
    margin-left: 58.33333333%;
  }

  .grid-offset-8 {
    margin-left: 66.66666667%;
  }

  .grid-offset-9 {
    margin-left: 75%;
  }

  .grid-offset-10 {
    margin-left: 83.33333333%;
  }

  .grid-offset-11 {
    margin-left: 91.66666667%;
  }

  .grid-spacing-0,
  .grid-col-spacing-0 {
    --bs-gutter-x: 0;
  }

  .grid-spacing-0,
  .grid-row-spacing-0 {
    --bs-gutter-y: 0;
  }

  .grid-spacing-1,
  .grid-col-spacing-1 {
    --bs-gutter-x: 4px;
  }

  .grid-spacing-1,
  .grid-row-spacing-1 {
    --bs-gutter-y: 4px;
  }

  .grid-spacing-2,
  .grid-col-spacing-2 {
    --bs-gutter-x: 8px;
  }

  .grid-spacing-2,
  .grid-row-spacing-2 {
    --bs-gutter-y: 8px;
  }

  .grid-spacing-3,
  .grid-col-spacing-3 {
    --bs-gutter-x: 16px;
  }

  .grid-spacing-3,
  .grid-row-spacing-3 {
    --bs-gutter-y: 16px;
  }

  .grid-spacing-4,
  .grid-col-spacing-4 {
    --bs-gutter-x: 24px;
  }

  .grid-spacing-4,
  .grid-row-spacing-4 {
    --bs-gutter-y: 24px;
  }

  .grid-spacing-5,
  .grid-col-spacing-5 {
    --bs-gutter-x: 48px;
  }

  .grid-spacing-5,
  .grid-row-spacing-5 {
    --bs-gutter-y: 48px;
  }

  @media (min-width: $SM) {
    .grid-col-sm-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .grid-col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .grid-col-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .grid-col-sm-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .grid-col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .grid-col-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .grid-col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .grid-col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .grid-col-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .grid-col-sm-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .grid-col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .grid-col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .grid-col-sm-12 {
      flex: 0 0 auto;
      width: 100%;
    }

    .grid-offset-sm-0 {
      margin-left: 0;
    }

    .grid-offset-sm-1 {
      margin-left: 8.33333333%;
    }

    .grid-offset-sm-2 {
      margin-left: 16.66666667%;
    }

    .grid-offset-sm-3 {
      margin-left: 25%;
    }

    .grid-offset-sm-4 {
      margin-left: 33.33333333%;
    }

    .grid-offset-sm-5 {
      margin-left: 41.66666667%;
    }

    .grid-offset-sm-6 {
      margin-left: 50%;
    }

    .grid-offset-sm-7 {
      margin-left: 58.33333333%;
    }

    .grid-offset-sm-8 {
      margin-left: 66.66666667%;
    }

    .grid-offset-sm-9 {
      margin-left: 75%;
    }

    .grid-offset-sm-10 {
      margin-left: 83.33333333%;
    }

    .grid-offset-sm-11 {
      margin-left: 91.66666667%;
    }

    .grid-spacing-sm-0,
    .grid-col-spacing-sm-0 {
      --bs-gutter-x: 0;
    }

    .grid-spacing-sm-0,
    .grid-row-spacing-sm-0 {
      --bs-gutter-y: 0;
    }

    .grid-spacing-sm-1,
    .grid-col-spacing-sm-1 {
      --bs-gutter-x: 4px;
    }

    .grid-spacing-sm-1,
    .grid-row-spacing-sm-1 {
      --bs-gutter-y: 4px;
    }

    .grid-spacing-sm-2,
    .grid-col-spacing-sm-2 {
      --bs-gutter-x: 8px;
    }

    .grid-spacing-sm-2,
    .grid-row-spacing-sm-2 {
      --bs-gutter-y: 8px;
    }

    .grid-spacing-sm-3,
    .grid-col-spacing-sm-3 {
      --bs-gutter-x: 16px;
    }

    .grid-spacing-sm-3,
    .grid-row-spacing-sm-3 {
      --bs-gutter-y: 16px;
    }

    .grid-spacing-sm-4,
    .grid-col-spacing-sm-4 {
      --bs-gutter-x: 24px;
    }

    .grid-spacing-sm-4,
    .grid-row-spacing-sm-4 {
      --bs-gutter-y: 24px;
    }

    .grid-spacing-sm-5,
    .grid-col-spacing-sm-5 {
      --bs-gutter-x: 48px;
    }

    .grid-spacing-sm-5,
    .grid-row-spacing-sm-5 {
      --bs-gutter-y: 48px;
    }
  }
  @media (min-width: $MD) {
    .grid-col-md-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .grid-col-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .grid-col-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .grid-col-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .grid-col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .grid-col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .grid-col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .grid-col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .grid-col-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .grid-col-md-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .grid-col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .grid-col-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .grid-col-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }

    .grid-offset-md-0 {
      margin-left: 0;
    }

    .grid-offset-md-1 {
      margin-left: 8.33333333%;
    }

    .grid-offset-md-2 {
      margin-left: 16.66666667%;
    }

    .grid-offset-md-3 {
      margin-left: 25%;
    }

    .grid-offset-md-4 {
      margin-left: 33.33333333%;
    }

    .grid-offset-md-5 {
      margin-left: 41.66666667%;
    }

    .grid-offset-md-6 {
      margin-left: 50%;
    }

    .grid-offset-md-7 {
      margin-left: 58.33333333%;
    }

    .grid-offset-md-8 {
      margin-left: 66.66666667%;
    }

    .grid-offset-md-9 {
      margin-left: 75%;
    }

    .grid-offset-md-10 {
      margin-left: 83.33333333%;
    }

    .grid-offset-md-11 {
      margin-left: 91.66666667%;
    }

    .grid-spacing-md-0,
    .grid-col-spacing-md-0 {
      --bs-gutter-x: 0;
    }

    .grid-spacing-md-0,
    .grid-row-spacing-md-0 {
      --bs-gutter-y: 0;
    }

    .grid-spacing-md-1,
    .grid-col-spacing-md-1 {
      --bs-gutter-x: 4px;
    }

    .grid-spacing-md-1,
    .grid-row-spacing-md-1 {
      --bs-gutter-y: 4px;
    }

    .grid-spacing-md-2,
    .grid-col-spacing-md-2 {
      --bs-gutter-x: 8px;
    }

    .grid-spacing-md-2,
    .grid-row-spacing-md-2 {
      --bs-gutter-y: 8px;
    }

    .grid-spacing-md-3,
    .grid-col-spacing-md-3 {
      --bs-gutter-x: 16px;
    }

    .grid-spacing-md-3,
    .grid-row-spacing-md-3 {
      --bs-gutter-y: 16px;
    }

    .grid-spacing-md-4,
    .grid-col-spacing-md-4 {
      --bs-gutter-x: 24px;
    }

    .grid-spacing-md-4,
    .grid-row-spacing-md-4 {
      --bs-gutter-y: 24px;
    }

    .grid-spacing-md-5,
    .grid-col-spacing-md-5 {
      --bs-gutter-x: 48px;
    }

    .grid-spacing-md-5,
    .grid-row-spacing-md-5 {
      --bs-gutter-y: 48px;
    }
  }
  @media (min-width: $LG) {
    .grid-col-lg-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .grid-col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .grid-col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .grid-col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .grid-col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .grid-col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .grid-col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .grid-col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .grid-col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .grid-col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .grid-col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .grid-col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .grid-col-lg-12 {
      flex: 0 0 auto;
      width: 100%;
    }

    .grid-offset-lg-0 {
      margin-left: 0;
    }

    .grid-offset-lg-1 {
      margin-left: 8.33333333%;
    }

    .grid-offset-lg-2 {
      margin-left: 16.66666667%;
    }

    .grid-offset-lg-3 {
      margin-left: 25%;
    }

    .grid-offset-lg-4 {
      margin-left: 33.33333333%;
    }

    .grid-offset-lg-5 {
      margin-left: 41.66666667%;
    }

    .grid-offset-lg-6 {
      margin-left: 50%;
    }

    .grid-offset-lg-7 {
      margin-left: 58.33333333%;
    }

    .grid-offset-lg-8 {
      margin-left: 66.66666667%;
    }

    .grid-offset-lg-9 {
      margin-left: 75%;
    }

    .grid-offset-lg-10 {
      margin-left: 83.33333333%;
    }

    .grid-offset-lg-11 {
      margin-left: 91.66666667%;
    }

    .grid-spacing-lg-0,
    .grid-col-spacing-lg-0 {
      --bs-gutter-x: 0;
    }

    .grid-spacing-lg-0,
    .grid-row-spacing-lg-0 {
      --bs-gutter-y: 0;
    }

    .grid-spacing-lg-1,
    .grid-col-spacing-lg-1 {
      --bs-gutter-x: 4px;
    }

    .grid-spacing-lg-1,
    .grid-row-spacing-lg-1 {
      --bs-gutter-y: 4px;
    }

    .grid-spacing-lg-2,
    .grid-col-spacing-lg-2 {
      --bs-gutter-x: 8px;
    }

    .grid-spacing-lg-2,
    .grid-row-spacing-lg-2 {
      --bs-gutter-y: 8px;
    }

    .grid-spacing-lg-3,
    .grid-col-spacing-lg-3 {
      --bs-gutter-x: 16px;
    }

    .grid-spacing-lg-3,
    .grid-row-spacing-lg-3 {
      --bs-gutter-y: 16px;
    }

    .grid-spacing-lg-4,
    .grid-col-spacing-lg-4 {
      --bs-gutter-x: 24px;
    }

    .grid-spacing-lg-4,
    .grid-row-spacing-lg-4 {
      --bs-gutter-y: 24px;
    }

    .grid-spacing-lg-5,
    .grid-col-spacing-lg-5 {
      --bs-gutter-x: 48px;
    }

    .grid-spacing-lg-5,
    .grid-row-spacing-lg-5 {
      --bs-gutter-y: 48px;
    }
  }
  @media (min-width: $XL) {
    .grid-col-xl-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .grid-col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .grid-col-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .grid-col-xl-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .grid-col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .grid-col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .grid-col-xl-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .grid-col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .grid-col-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .grid-col-xl-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .grid-col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .grid-col-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .grid-col-xl-12 {
      flex: 0 0 auto;
      width: 100%;
    }

    .grid-offset-xl-0 {
      margin-left: 0;
    }

    .grid-offset-xl-1 {
      margin-left: 8.33333333%;
    }

    .grid-offset-xl-2 {
      margin-left: 16.66666667%;
    }

    .grid-offset-xl-3 {
      margin-left: 25%;
    }

    .grid-offset-xl-4 {
      margin-left: 33.33333333%;
    }

    .grid-offset-xl-5 {
      margin-left: 41.66666667%;
    }

    .grid-offset-xl-6 {
      margin-left: 50%;
    }

    .grid-offset-xl-7 {
      margin-left: 58.33333333%;
    }

    .grid-offset-xl-8 {
      margin-left: 66.66666667%;
    }

    .grid-offset-xl-9 {
      margin-left: 75%;
    }

    .grid-offset-xl-10 {
      margin-left: 83.33333333%;
    }

    .grid-offset-xl-11 {
      margin-left: 91.66666667%;
    }

    .grid-spacing-xl-0,
    .grid-col-spacing-xl-0 {
      --bs-gutter-x: 0;
    }

    .grid-spacing-xl-0,
    .grid-row-spacing-xl-0 {
      --bs-gutter-y: 0;
    }

    .grid-spacing-xl-1,
    .grid-col-spacing-xl-1 {
      --bs-gutter-x: 4px;
    }

    .grid-spacing-xl-1,
    .grid-row-spacing-xl-1 {
      --bs-gutter-y: 4px;
    }

    .grid-spacing-xl-2,
    .grid-col-spacing-xl-2 {
      --bs-gutter-x: 8px;
    }

    .grid-spacing-xl-2,
    .grid-row-spacing-xl-2 {
      --bs-gutter-y: 8px;
    }

    .grid-spacing-xl-3,
    .grid-col-spacing-xl-3 {
      --bs-gutter-x: 16px;
    }

    .grid-spacing-xl-3,
    .grid-row-spacing-xl-3 {
      --bs-gutter-y: 16px;
    }

    .grid-spacing-xl-4,
    .grid-col-spacing-xl-4 {
      --bs-gutter-x: 24px;
    }

    .grid-spacing-xl-4,
    .grid-row-spacing-xl-4 {
      --bs-gutter-y: 24px;
    }

    .grid-spacing-xl-5,
    .grid-col-spacing-xl-5 {
      --bs-gutter-x: 48px;
    }

    .grid-spacing-xl-5,
    .grid-row-spacing-xl-5 {
      --bs-gutter-y: 48px;
    }
  }
}
