@import 'mixins';
@import 'variables';

@mixin _mat-form-field($backgroundColor) {
  .mat-mdc-text-field-wrapper {
    background-color: $backgroundColor !important;

    .mat-mdc-form-field-focus-overlay {
      display: none;
    }
  }
}
@mixin mat-form-field($backgroundColor) {
  .mat-form-field-#{$backgroundColor} {
    @include _mat-form-field($backgroundColor);
  }
}
@include mat-form-field($backgroundColor: transparent);
@include mat-form-field($backgroundColor: white);

@mixin _mat-form-field-text($color) {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        .mat-mdc-input-element {
          color: $color !important;
          caret-color: $color !important;
        }
        .mdc-floating-label {
          color: $color !important;
        }
      }
    }
  }
  .mat-mdc-select-trigger {
    .mat-mdc-select-value {
      .mat-mdc-select-value-text {
        color: $color !important;
      }
    }
    .mat-mdc-select-arrow-wrapper {
      .mat-mdc-select-arrow {
        color: $color !important;
      }
    }
  }
}
@mixin mat-form-field-text($color) {
  .mat-form-field-text-#{$color} {
    @include _mat-form-field-text($color);
  }
}
@include mat-form-field-text($color: white);

@mixin _mat-form-field-ripple($color) {
  .mat-mdc-text-field-wrapper {
    .mdc-line-ripple::before {
      border-bottom-color: $color !important;
    }

    .mdc-line-ripple::after {
      border-bottom-color: $color !important;
    }
  }
}
@mixin mat-form-field-ripple($color) {
  .mat-form-field-ripple-#{$color} {
    @include _mat-form-field-ripple($color);
  }
}
@include mat-form-field-ripple($color: white);
@include mat-form-field-ripple($color: transparent);

@mixin _mat-form-field-shadow($shadow) {
  .mat-mdc-text-field-wrapper {
    box-shadow: $shadow;
  }
}
@mixin mat-form-field-shadow($name, $shadow) {
  .mat-form-field-shadow-#{$name} {
    @include _mat-form-field-shadow($shadow);
  }
}
@include mat-form-field-shadow($name: basic, $shadow: $basic-shadow);

@mixin _mat-form-field-px($size) {
  .mat-mdc-text-field-wrapper {
    padding-left: $size !important;
    padding-right: $size !important;
  }
}
@mixin mat-form-field-px($size) {
  .mat-form-field-px-#{$size} {
    @include _mat-form-field-px($size);
  }
}
@include mat-form-field-px($size: 0);

@mixin _mat-form-field-py($size) {
  .mat-mdc-text-field-wrapper {
    padding-top: $size !important;
    padding-bottom: $size !important;
  }
}
@mixin mat-form-field-py($size) {
  .mat-form-field-py-#{$size} {
    @include _mat-form-field-py($size);
  }
}
// No vertical padding
@include mat-form-field-py($size: 0);

@mixin _mat-form-field-h($size) {
  .mat-mdc-text-field-wrapper {
    height: $size !important;

    .mat-mdc-form-field-flex {
      height: $size !important;
    }
  }
}
@mixin mat-form-field-h($name, $size) {
  .mat-form-field-h-#{$name} {
    @include _mat-form-field-h($size);
  }
}
@include mat-form-field-h($name: 0, $size: 0);
@include mat-form-field-h($name: auto, $size: auto);
@include mat-form-field-h($name: 25, $size: 25px);
@include mat-form-field-h($name: 35, $size: 35px);
@include mat-form-field-h($name: 40, $size: 40px);
@include mat-form-field-h($name: 45, $size: 45px);
@include mat-form-field-h($name: full, $size: 100%);

@mixin _mat-form-field-error($size) {
  .mat-mdc-form-field-subscript-wrapper {
    font-size: $size !important;
    position: absolute;

    .mat-mdc-form-field-error-wrapper {
      padding: 0 !important;
    }
  }
}
@mixin mat-form-field-error($name, $size) {
  .mat-form-field-#{$name}-error {
    @include _mat-form-field-error($size);
  }
}
@include mat-form-field-error($name: small, $size: 65%);

@mixin _mat-form-field-floating-label($paddingTop, $paddingBottom, $topLocation) {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding-top: $paddingTop !important;
        padding-bottom: $paddingBottom !important;

        .mdc-floating-label {
          top: $topLocation !important;
        }
      }
      .mat-mdc-form-field-icon-suffix {
        padding-top: $paddingTop !important;
        padding-bottom: $paddingBottom !important;
      }
    }
  }
}
@mixin mat-form-field-floating-label($name, $paddingTop, $paddingBottom, $topLocation) {
  .mat-form-field-floating-label-#{$name} {
    @include _mat-form-field-floating-label($paddingTop, $paddingBottom, $topLocation);
  }
}
@include mat-form-field-floating-label($name: base, $paddingTop: 31px, $paddingBottom: 4px, $topLocation: 37px);
@include mat-form-field-floating-label($name: zero, $paddingTop: 0, $paddingBottom: 0, $topLocation: 0);

@mixin _mat-form-field-align($type) {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      align-items: $type !important;
    }
  }
}
@mixin mat-form-field-align($name, $type) {
  .mat-form-field-align-#{$name} {
    @include _mat-form-field-align($type);
  }
}
@include mat-form-field-align($name: center, $type: center);

@mixin _mat-form-field-font($size) {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        mat-label {
          font-size: $size !important;
        }
        .mat-mdc-input-element {
          font-size: $size !important;
        }
      }
    }
  }
}
@mixin mat-form-field-font($name, $size) {
  .mat-form-field-font-#{$name} {
    @include _mat-form-field-font($size);
  }
}
@include mat-form-field-font($name: 13px, $size: 13px);
@include mat-form-field-font($name: 14px, $size: 14px);

.mat-form-field-transparent-dense {
  @include _mat-form-field(transparent);
  @include _mat-form-field-px(0);
  @include _mat-form-field-h(auto);
}

.mat-form-field-white-dense {
  @include _mat-form-field(white);
  @include _mat-form-field-px(0);
  @include _mat-form-field-h(auto);
}

.mat-form-field-white-spacious-basic-shadow-no-ripple {
  @include _mat-form-field(white);
  @include _mat-form-field-h(100%);
  @include _mat-form-field-ripple(transparent);
  @include _mat-form-field-shadow($basic-shadow);
}

.mat-form-field-white-basic-shadow {
  @include _mat-form-field(white);
  @include _mat-form-field-shadow($basic-shadow);
}

.mat-form-field-transparent-floating-label {
  @include _mat-form-field(transparent);
  @include _mat-form-field-px(0);
  @include _mat-form-field-floating-label($paddingTop: 31px, $paddingBottom: 4px, $topLocation: 37px);
  @include _mat-form-field-error($size: 65%);
}

.mat-form-field-fill-width .mat-mdc-form-field-infix {
  width: 100% !important;
}

.mat-form-field-absolute-bottom {
  .mat-mdc-text-field-wrapper {
    bottom: 0;
    position: absolute;
    z-index: 10;
  }
}
